<template>
  <div>
    <b-container fluid>
        <b-row>
            <b-col sm="12" class="bannerContent">
                <img class="for_desktop" src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/clinical-1.jpeg" width="100%" alt="Apple Stem Cell" desc="Uji Klinis Apple Stem Cell">
                <img class="for_tablet" src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/clinical-1.jpeg" width="100%" alt="Apple Stem Cell" desc="Uji Klinis Apple Stem Cell">
                <img class="for_mobile" src="../../assets/images/clinical-1-potrait.jpg" width="100%" alt="Apple Stem Cell" desc="Uji Klinis Apple Stem Cell">
            </b-col>
            <b-col sm="12" class="bannerContent bgClinical">
                <div class="for_desktop clinicalContentProduct">
                    <b-row>
                        <b-col sm="7">
                            <b-row>
                            <b-col sm="12"><p v-html="data.clinicalAppleSwissDesc"></p></b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="5">
                        <img src="../../assets/images/icon/clinical-2.png" width="100%" alt="Swiss" desc="Uji Klinis Dapi Swiss">
                        </b-col>
                    </b-row>
                </div>

                <div class="for_tablet clinicalContentProduct">
                    <b-row>
                        <b-col sm="7">
                            <b-row>
                                <b-col sm="12"><p v-html="data.clinicalAppleSwissDesc"></p></b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="5">
                        <img src="../../assets/images/icon/clinical-2.png" width="100%" alt="Swiss" desc="Uji Klinis Dapi Swiss">
                        </b-col>
                    </b-row>
                </div>

                <div class="for_mobile clinicalContentProductMobile">
                    <b-row>
                        <b-col sm="5">
                            <img src="../../assets/images/icon/clinical-2.png" width="100%" alt="Swiss" desc="Uji Klinis Dapi Swiss">
                        </b-col>
                        <b-col sm="7">
                            <b-row>
                                <b-col sm="12"><p v-html="data.clinicalAppleSwissDesc"></p></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
                
            </b-col>
            <b-col sm="12" class="bannerContent bgClinical">
                <div class="for_desktop ">
                    <b-row>
                        <b-col sm="5" class="clinicalContentProduct">
                        <img src="../../assets/images/icon/clinical-3.png" width="100%" alt="Uji Klinis Biogreen" desc="Uji Klinis Biogreen">
                        </b-col>
                        <b-col sm="7" class="clinicalContentProductRight">
                            <b-row>
                            <b-col sm="12">
                                <p v-html="data.clinicalAntiAggingDesc1"></p>
                                <p v-html="data.clinicalAntiAggingDesc2" style="margin-top:-8rem"></p>
                            </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>

                <div class="for_tablet">
                    <b-row>
                        <b-col sm="5" class="clinicalContentProduct">
                        <img src="../../assets/images/icon/clinical-3.png" width="100%" alt="Uji Klinis Biogreen" desc="Uji Klinis Biogreen">
                        </b-col>
                        <b-col sm="7" class="clinicalContentProductRight">
                            <b-row>
                            <b-col sm="12">
                                <p v-html="data.clinicalAntiAggingDesc1"></p>
                                <p v-html="data.clinicalAntiAggingDesc2" style="margin-top:-4rem"></p>
                            </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>

                <div class="for_mobile clinicalContentProductMobile">
                    <b-row>
                        <b-col sm="12" align="center">
                        <img src="../../assets/images/icon/clinical-3-mobile.png" width="100%" alt="Uji Klinis Biogreen" desc="Uji Klinis Biogreen">
                        </b-col>
                        <b-col sm="12">
                            <b-row>
                            <b-col sm="12">
                                <p v-html="data.clinicalAntiAggingDesc1"></p>
                                <p v-html="data.clinicalAntiAggingDesc2" style="margin-top:-1rem"></p>
                            </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
                
            </b-col>
            <b-col sm="12" class="bannerContent">
                <div class="for_desktop bgClinical">
                    <b-row>
                        <b-col sm="12"><h1 class="titlePaten">{{data.clinicalPatenTitle}}</h1></b-col>
                        <b-col sm="6" class="clinicalContentPaten">
                            <img src="../../assets/images/icon/clinical-4-swisskorea.png" alt="Paten" desc="Uji Klinis Paten">
                            <img src="../../assets/images/icon/clinical-4-eropeus.png" alt="Paten" desc="Uji Klinis Paten">
                            <p>1. {{data.clinicalPaten1Desc1x1}} <br/> 2. {{data.clinicalPaten1Desc1x2}} </p>
                            <img src="../../assets/images/icon/clinical-4-eropeus2.png" alt="Paten" desc="Uji Klinis Paten">
                            <p>1. {{data.clinicalPaten1Desc2x1}} <br/> 2. {{data.clinicalPaten1Desc2x2}} </p>
                        </b-col>
                        <b-col sm="6" class="clinicalContentPaten">
                            <img src="../../assets/images/icon/clinical-4-swisskorea.png" alt="Paten" desc="Uji Klinis Paten">
                            <img src="../../assets/images/icon/clinical-4-ww.png" alt="Paten" desc="Uji Klinis Paten">
                            <p>1. {{data.clinicalPaten1Desc3x1}} <br/> 2. {{data.clinicalPaten1Desc3x2}} <br/> 3. {{data.clinicalPaten1Desc3x3}} <br/> 4. {{data.clinicalPaten1Desc3x4}} </p>
                        </b-col>
                    </b-row>
                </div>
                <div class="for_tablet bgClinical">
                    <b-row>
                        <b-col sm="12"><h1 class="titlePaten">{{data.clinicalPatenTitle}}</h1></b-col>
                        <b-col sm="6" class="clinicalContentPaten">
                            <img src="../../assets/images/icon/clinical-4-swisskorea.png" alt="Paten" desc="Uji Klinis Paten">
                            <img src="../../assets/images/icon/clinical-4-eropeus.png" alt="Paten" desc="Uji Klinis Paten">
                            <p>1. {{data.clinicalPaten1Desc1x1}} <br/> 2. {{data.clinicalPaten1Desc1x2}}</p>
                            <img src="../../assets/images/icon/clinical-4-eropeus2.png" alt="Paten" desc="Uji Klinis Paten">
                            <p>1. {{data.clinicalPaten1Desc2x1}} <br/> 2. {{data.clinicalPaten1Desc2x2}} </p>
                        </b-col>
                        <b-col sm="6" class="clinicalContentPaten">
                            <img src="../../assets/images/icon/clinical-4-swisskorea.png" alt="Paten" desc="Uji Klinis Paten">
                            <img src="../../assets/images/icon/clinical-4-ww.png" alt="Paten" desc="Uji Klinis Paten">
                            <p>1. {{data.clinicalPaten1Desc3x1}} <br/> 2. {{data.clinicalPaten1Desc3x2}} <br/> 3. {{data.clinicalPaten1Desc3x3}} <br/> 4. {{data.clinicalPaten1Desc3x4}} </p>
                        </b-col>
                    </b-row>
                </div>
                <div class="for_mobile bgClinical">
                    <b-row>
                        <b-col sm="12"><h1 class="titlePaten">{{data.clinicalPatenTitle}}</h1></b-col>
                        <b-col sm="6" class="clinicalContentPaten">
                            <img src="../../assets/images/icon/clinical-flag-1-mobile.png" alt="Paten" desc="Uji Klinis Paten">
                            <p>1. {{data.clinicalPaten1Desc1x1}} <br/> 2. {{data.clinicalPaten1Desc1x2}} </p>
                            <img src="../../assets/images/icon/clinical-4-eropeus2.png" alt="Paten" desc="Uji Klinis Paten">
                            <p>1. {{data.clinicalPaten1Desc2x1}} <br/> 2. {{data.clinicalPaten1Desc2x2}} </p>
                        </b-col>
                        <b-col sm="6" class="clinicalContentPaten">
                            <img src="../../assets/images/icon/clinical-flag-2-mobile.png" alt="Paten" desc="Uji Klinis Paten">
                            <p>1. {{data.clinicalPaten1Desc3x1}} <br/> 2. {{data.clinicalPaten1Desc3x2}} <br/> 3. {{data.clinicalPaten1Desc3x3}} <br/> 4. {{data.clinicalPaten1Desc3x4}} </p>
                        </b-col>
                    </b-row>
                </div>

            </b-col>
            <b-col sm="12" class="bannerContent clinicalContentTestimoni bgClinical">
                <div class="for_desktop">
                    <b-row>
                        <b-col sm="4" class="clinicalContentTestimoniItem">
                            <b-row>
                            <b-col sm="12"><p v-html="data.clinicalTestimoniDesc"></p></b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="8" class="clinicalContentTestimoniItem">
                            <!-- <b-row>
                                <b-col cols="4" class="clinicalContentTestimoniItem"><img src="../../assets/images/icon/clinical-jlo.jpeg" alt="Selebriti" desc="Uji Klinis Selebriti Memakai"></b-col>
                                <b-col cols="4" class="clinicalContentTestimoniItem">
                                    <b-row>
                                        <b-col cols="12"><img src="../../assets/images/icon/clinical-victoria.jpeg" alt="Selebriti" desc="Uji Klinis Selebriti Memakai"></b-col>
                                        <b-col cols="12"><img src="../../assets/images/icon/clinical-hellen.jpeg" alt="Selebriti" desc="Uji Klinis Selebriti Memakai"></b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="4" class="clinicalContentTestimoniItem"><img src="../../assets/images/icon/clinical-mobama.jpeg" alt="Selebriti" desc="Uji Klinis Selebriti Memakai"></b-col>
                            </b-row> -->
                            <img src="../../assets/images/icon/clinical-testimoni.png" alt="Selebriti" desc="Uji Klinis Selebriti Memakai">
                        </b-col>
                    </b-row>
                </div>
                <div class="for_tablet">
                    <b-row>
                        <b-col sm="5" class="clinicalContentTestimoniItem">
                            <b-row>
                            <b-col sm="12"><p v-html="data.clinicalTestimoniDesc"></p></b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="7" class="clinicalContentTestimoniItem">
                            <!-- <b-row>
                                <b-col cols="4" class="clinicalContentTestimoniItem"><img src="../../assets/images/icon/clinical-jlo.jpeg" alt="Selebriti" desc="Uji Klinis Selebriti Memakai"></b-col>
                                <b-col cols="4" class="clinicalContentTestimoniItem">
                                    <b-row>
                                        <b-col cols="12"><img src="../../assets/images/icon/clinical-victoria.jpeg" alt="Selebriti" desc="Uji Klinis Selebriti Memakai"></b-col>
                                        <b-col cols="12"><img src="../../assets/images/icon/clinical-hellen.jpeg" alt="Selebriti" desc="Uji Klinis Selebriti Memakai"></b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="4" class="clinicalContentTestimoniItem"><img src="../../assets/images/icon/clinical-mobama.jpeg" alt="Selebriti" desc="Uji Klinis Selebriti Memakai"></b-col>
                            </b-row> -->
                            <img src="../../assets/images/icon/clinical-testimoni.png" alt="Selebriti" desc="Uji Klinis Selebriti Memakai">
                        </b-col>
                    </b-row>
                </div>
                <div class="for_mobile">
                    <b-row>
                        <b-col sm="12" class="clinicalContentTestimoni">
                            <img src="../../assets/images/icon/clinical-testimoni-mobile.png" alt="Selebriti" desc="Uji Klinis Selebriti Memakai">
                        </b-col>
                        <b-col sm="12">
                            <b-row>
                            <b-col sm="12"><p v-html="data.clinicalTestimoniDesc"></p></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>

            </b-col>
        </b-row>
        
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
        bahasa:'id',
        data: {
            clinicalTitle:null,
            clinicalAppleSwissDesc:null,

            clinicalAntiAggingDesc1:null,
            clinicalAntiAggingDesc2:null,

            clinicalPatenTitle:null,


            clinicalPaten1Desc1x1:null,
            clinicalPaten1Desc1x2:null,

            clinicalPaten1Desc2x1:null,
            clinicalPaten1Desc2x2:null,

            clinicalPaten1Desc3x1:null,
            clinicalPaten1Desc3x2:null,
            clinicalPaten1Desc3x3:null,
            clinicalPaten1Desc3x4:null,

            clinicalTestimoniDesc:null

        }
      }
    },
    mounted() {
        this.bahasa = localStorage.getItem('bahasa')
        axios.get(`../../lang_${this.bahasa}.json`).then(response => {
            // console.log(response.data.data[8])
            this.data.clinicalAppleSwissDesc = response.data.data[8].clinical[0].clinicalAppleSwissDesc

            this.data.clinicalAntiAggingDesc1 = response.data.data[8].clinical[0].clinicalAntiAggingDesc1
            this.data.clinicalAntiAggingDesc2 = response.data.data[8].clinical[0].clinicalAntiAggingDesc2

            this.data.clinicalPatenTitle = response.data.data[8].clinical[0].clinicalPatenTitle

            this.data.clinicalPaten1Desc1x1 = response.data.data[8].clinical[0].clinicalPaten1Desc1x1
            this.data.clinicalPaten1Desc1x2 = response.data.data[8].clinical[0].clinicalPaten1Desc1x2

            this.data.clinicalPaten1Desc2x1 = response.data.data[8].clinical[0].clinicalPaten1Desc2x1
            this.data.clinicalPaten1Desc2x2 = response.data.data[8].clinical[0].clinicalPaten1Desc2x2

            this.data.clinicalPaten1Desc3x1 = response.data.data[8].clinical[0].clinicalPaten1Desc3x1
            this.data.clinicalPaten1Desc3x2 = response.data.data[8].clinical[0].clinicalPaten1Desc3x2
            this.data.clinicalPaten1Desc3x3 = response.data.data[8].clinical[0].clinicalPaten1Desc3x3
            this.data.clinicalPaten1Desc3x4 = response.data.data[8].clinical[0].clinicalPaten1Desc3x4

            this.data.clinicalTestimoniDesc = response.data.data[8].clinical[0].clinicalTestimoniDesc
            
        })
    }
  }
</script>

